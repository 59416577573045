/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { BackButton, Container, Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { Slugs, useTranslation } from "@trueskin-web/translations"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Info = ({ label, value }) => (
  <div
    sx={{
      "&:not(:last-of-type)": {
        pb: 4,
        mb: 6,
        borderBottom: "1px",
      },
    }}
  >
    <div sx={{ fontSize: 1, mb: 1 }}>{label}</div>
    <div sx={{ fontSize: 3 }}>{value}</div>
  </div>
)

const Doctor = ({
  data: {
    strapi: { ourDoctorsPage, doctor },
  },
}) => {
  const { t } = useTranslation()

  return (
    <Layout title={ourDoctorsPage.title}>
      <Seo title={doctor.name} />

      <Container>
        <div sx={{ mt: 7, mb: [4, 5] }}>
          <BackButton href={Slugs["our-doctors"].url} />
        </div>

        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["auto", "5fr 7fr"],
            gap: 7,
            mb: [null, 10],
          }}
        >
          <div>
            {doctor.image && (
              <GatsbyImage
                image={doctor.image.imageFile.childImageSharp.gatsbyImageData}
                alt=""
                sx={{
                  maxHeight: [400, "initial"],
                  mb: 5,
                }}
              />
            )}

            <Heading as="h1" size="lg" mb={[4, 2]}>
              {doctor.name}
            </Heading>

            <Heading as="h3" size="xsm" mb={6}>
              {doctor.specialty}
            </Heading>

            {doctor.quote && (
              <blockquote
                sx={{
                  position: "relative",
                  m: 0,
                  p: 6,
                  borderTop: "1px",
                  borderBottom: "1px",
                  "&:before": {
                    content: "' '",
                    position: "absolute",
                    top: "-1px",
                    left: "50%",
                    height: "1px",
                    width: "64px",
                    transform: "translateX(-50%)",
                    backgroundColor: "background",
                  },
                  "&:after": {
                    content: "'“'",
                    position: "absolute",
                    top: "-100px",
                    left: "50%",
                    fontSize: "100px",
                    transform: "rotate(-180deg) translateX(-50%)",
                    transformOrigin: "left",
                    pointerEvents: "none",
                  },
                }}
              >
                {doctor.quote}
              </blockquote>
            )}
          </div>

          <div
            sx={{
              mx: [-4, 0],
              pt: 7,
              pb: [9, 7],
              px: [4, 7],
              backgroundColor: "lightRose",
            }}
          >
            {doctor.studies && (
              <Info
                label={t("Templates.Doctor.studies")}
                value={doctor.studies}
              />
            )}
            {doctor.trainings && (
              <Info
                label={t("Templates.Doctor.trainings")}
                value={doctor.trainings}
              />
            )}
            {doctor.priorities && (
              <Info
                label={t("Templates.Doctor.priorities")}
                value={doctor.priorities}
              />
            )}
            {doctor.science && (
              <Info
                label={t("Templates.Doctor.science")}
                value={doctor.science}
              />
            )}
            {doctor.abroad && (
              <Info
                label={t("Templates.Doctor.abroad")}
                value={doctor.abroad}
              />
            )}
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($doctorId: ID!) {
    strapi {
      ourDoctorsPage {
        title
      }
      doctor(id: $doctorId) {
        name
        image {
          url
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 500, aspectRatio: 1.3, layout: CONSTRAINED)
            }
          }
        }
        specialty
        quote
        studies
        trainings
        priorities
        science
        abroad
      }
    }
  }
`

export default Doctor
